.OrganizationMember {
	display: inline-block;
	border: 3px solid;
	padding: 0 5px;
	.bullet {
		display: inline-block;
		border-radius: 50%;
		height: .5em;
		width: .5em;
		background-color: gray;
	}
	.bullet + *{
		vertical-align: middle;
	}
	.bullet.owner {
		background-color: green;
	}
	.bullet.admin {
		background-color: greenyellow;
	}
	.bullet.manager {
		background-color: yellow;
	}
}

.OrganizationMember.clickable {
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
}
