.UserPage{
	thead {
		position: sticky;
		top: 0;
		background: white;

		th {
			cursor: pointer;
			text-align: left;
		}
	}
	tfoot {
		position: sticky;
		bottom: 0;
		background: white;
	}
	tfoot td {
		font-weight: bold;
	}
	.deleted {
		background: #ff3c00;
	}
	.campaign-link{
		text-decoration: none;
		font-size: 90%;
		margin-left: 5px;
	}
}