.firebase-emulator-warning {
	display: none;
}

.MFAModal {
	background: rgba(0,0,0,.4);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: column;
	z-index: 10;
}
.MFAModal .modal {
	background: antiquewhite;
	max-width: 80%;
	margin: auto;
	width: 750px;
	padding: 1em;
}
